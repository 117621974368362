import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { postAuthenticateToken } from '../../apis';
import { clientKey, redirectKey, smarterhealth } from '@features/common/config';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const GlobalAuthTokenHandler = ({ onAuthComplete }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true); // Track loading state for authentication
  const tokenKey = 'token';
  const [cookie, setCookie] = useState({
    client: cookies.get('client'),
    userId: cookies.get('userId'),
  });

  const redirectToLogin = () => {
    window.sessionStorage.setItem(redirectKey, window.location.pathname);
    window.location = `/app/${
      cookie.client ?? window.sessionStorage.getItem(clientKey) ?? smarterhealth
    }/login`;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get(tokenKey);

    if (token) {
      // Call the authentication API
      postAuthenticateToken({ token })
        .then((res) => {
          if (res.code && res.data) {
            // Remove 'token' query parameter and keep other params intact
            queryParams.delete(tokenKey);

            // Replace the URL with the updated query params
            const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
            window.location.href = newUrl; // Redirect to the new URL to refresh auth cookies
          } else {
            setLoading(false); // Stop loading on failure
            onAuthComplete();
          }
        })
        .catch((err) => {
          console.error('Authentication error:', err);
          const redirect_url = err.response?.data?.redirect_url;
          if (redirect_url) {
            window.location.href = redirect_url;
          } else {
            redirectToLogin();
          }
        })
        .finally(() => {
          window.sessionStorage.removeItem(redirectKey);
        });
    } else {
      if (!cookie.userId) {
        redirectToLogin();
        return;
      } else {
        setLoading(false);
        onAuthComplete();
      }
    }
  }, [location.search, onAuthComplete]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state until authentication is complete
  }

  return null;
};

export default GlobalAuthTokenHandler;
